import React from 'react';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import {
  HomePageRequestedReviewsCard,
  HomePageYourOpenPullRequestsCard,
} from '@roadiehq/backstage-plugin-github-pull-requests';

import {
  HomePageToolkit,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { FeatureFlagged } from '@backstage/core-app-api';

import NNLogoBlue from "../../assets/nn__logo--blue.png";
import NNLogoWhite from "../../assets/nn__logo--white.png";
import HomePageInfoBox from './HomePageInfoBox';
import { developerPortalLinks, novoNordiskLinks } from './toolkitLinks';

const CustomHomePageSearchBar = styled(HomePageSearchBar)(({ theme }) => ({
  '& .searchBarInput': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px !important',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  '& .searchBarOutline': {
    borderStyle: 'none',
  },
}));

const HeadingTitle = styled('h1')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '36px',
  fontWeight: 600,
  paddingLeft: '20px',
}));

const HeadingImage = styled('img')({
  placeSelf: 'center',
});

const HomePage = () => {
  const theme = useTheme();
  const logoType = theme.palette.mode === 'dark' ? NNLogoWhite : NNLogoBlue;

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Grid container item xs={12} justifyContent='center'>
              <HeadingImage src={logoType} alt='Novo Nordisk logo' height={80}/>
              <HeadingTitle>Developer Portal</HeadingTitle>
            </Grid>
            <Grid container item md={8} xs={12} justifyContent='center'>
              <CustomHomePageSearchBar
                InputProps={{ classes: { root: 'searchBarInput', notchedOutline: 'searchBarOutline' }}}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid container item md={6} xs={12}>
                <Grid item xs={12} padding={1}>
                  <HomePageInfoBox />
                </Grid>
                <FeatureFlagged with="github-pr-homepage">
                  <Grid item xs={12} padding={1}>
                    <HomePageRequestedReviewsCard />
                  </Grid>
                </FeatureFlagged>
              </Grid>
              <Grid container item md={6} xs={12}>
                <Grid item xs={12} padding={1}>
                  <HomePageToolkit
                    title="Developer Experience Links"
                    tools={developerPortalLinks}
                  />
                </Grid>
                <Grid item xs={12} padding={1}>
                  <HomePageToolkit
                    title="Novo Nordisk Links"
                    tools={novoNordiskLinks}
                  />
                </Grid>
                <Grid item xs={12} padding={1}>
                  <HomePageStarredEntities />
                </Grid>
                <FeatureFlagged with="github-pr-homepage">
                  <Grid item xs={12} padding={1}>
                    <HomePageYourOpenPullRequestsCard />
                  </Grid>
                </FeatureFlagged>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};

export default HomePage;